import React from 'react';
import SimpleBar from 'simplebar-react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Image, Table, Accordion, Card } from 'react-bootstrap';

// Components
import { Header, DetailAccordion } from '../../DetailTemplate';
import { ErrorMessage } from '../../Extras';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Styles
import '../../Detail/Detail.scss';

export default class Detail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      achievementError: false
    }

    this.request = utils.request.bind(this);
  }

  componentDidMount() {
    this.getAchievement();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      this.getAchievement();
    }
  }

  getAchievement() {
    this.request(`/achievements/${this.props.selected}`, 'achievement');
  }

  render() {
    if (this.state.achievementError) {
      return <ErrorMessage error={this.state.achievementError} />;
    }

    if (this.state.achievementLoaded) {
      return (
        <SimpleBar 
          style={{ height: 'calc(100vh - 56px)' }} 
          className="detail-component">
          <MetaDecorator title={this.state.achievement.sDisplayName} />
          <Header toggleCompactMode={this.props.toggleCompactMode}>
            <Image 
              src={this.state.achievement.aMilestones[0].eRoleIcon}
              alt={this.state.achievement.sDisplayName}
              onError={utils.handleImageError} />
            <h4 className="d-inline align-middle">
              {this.state.achievement.sDisplayName}
            </h4>
          </Header>
          <Row>
            <Description achievement={this.state.achievement} />
            <Col md={12} className="p-0">
            {this.state.achievement.aMilestones.map((m, i) => (
              <Action achievement={this.state.achievement} milestone={m} index={i} />
            ))}
            </Col>
          </Row>
        </SimpleBar>
      )
    } else {
      return utils.loadingCircle();
    }
  }
}

class Description extends React.Component {
  render() {
    return (
      <Col md={12} className="description">
        <Row noGutters>
          <Col md={8} className="mb-3">
            <h6>DESCRIPTION</h6>
            <small>{this.props.achievement.sDescription}</small>
          </Col>
        </Row>
      </Col>
    )
  }
}

class Action extends React.Component {
  calculateDifference(level) {
    let final = this.props.achievement.aMilestones[level].fPassMark_0;

    if (level > 0) final -= this.props.achievement.aMilestones[level-1].fPassMark_0;
    
    return final;
  }

  render() {
    
    var collapseOpen = '0';
    const index = this.props.index;
    const milestone = this.props.milestone;
    const rewards = utils.combineRewardPackageItems(milestone.eReward);

    var toggle = (
      <>
      {`RANK ${index+1} // ${milestone.sTitle}`}
      <br />
      <span className="text-muted">
        {
          `${this.calculateDifference(index).toLocaleString()} action${this.calculateDifference(index) > 1 ? 's' : ''} 
          // ${milestone.fPassMark_0.toLocaleString()} total action${milestone.fPassMark_0 > 1 ? 's' : ''}
          // (${this.props.achievement.eActivities_0.sAPBDB})`
        }
      </span>
      </>
    )

    var collapse = <p className="m-3">No rewards.</p>;

    if (rewards.length > 0) {
      collapseOpen = '1';
      collapse = (
        <Table className="item-table m-0">
          <tbody>
            {rewards.map(item => (
            <tr key={item.sAPBDB}>
              <td className="small-width">
                <NavLink to={`/items/${item.sAPBDB}`}>
                  <Image 
                    width={32}
                    src={item.eHUDImage} 
                    alt={item.sDisplayName}
                    onError={utils.handleImageError} />
                </NavLink>
              </td>
              <td>
                <NavLink 
                  to={`/items/${item.sAPBDB}`} 
                  dangerouslySetInnerHTML={{__html: utils.convertColorCode(item.sDisplayName)}} />
              </td>
            </tr>
            ))}
          </tbody>
        </Table>
      )
    }

    return (
      <DetailAccordion 
        ukey={milestone.sAPBDB} 
        toggle={toggle} 
        collapse={collapse}
        open={collapseOpen} />
    )
  }
}
