import React from 'react';
import { Card, Accordion } from 'react-bootstrap';

// Styles
import './Detail/Detail.scss';

export class Header extends React.Component { 
  render() { 
    return (
      <div className="header">
        <button className="button-clear" onClick={this.props.toggleCompactMode}>
          <i className="fas fa-arrow-left"></i>
        </button>
        <div className="text-center d-inline">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export class DetailAccordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: props.open || '0' }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.open !== this.props.open) {
      this.setState({ open: this.props.open });
    }
  }

  handleToggleClick = () => {
    if (this.state.open === '0') {
      this.setState({ open: '1' });
    } else {
      this.setState({ open: '0' });
    }
  }

  render() {
    return (
      <Accordion key={this.props.ukey} activeKey={this.state.open} className="flipped-accordion">
        <Card className="mission-stage">
          <Accordion.Collapse eventKey={'1'} className="flipped-collapse stage-collapse">
            {this.props.collapse}
          </Accordion.Collapse>
          <Accordion.Toggle 
            as={Card.Header} 
            variant="link" 
            eventKey={'1'}
            onClick={this.handleToggleClick}
            className="flipped-header stage-header"
          >
            <h6>{this.props.toggle}</h6>
          </Accordion.Toggle>
        </Card>
      </Accordion>
    )
  }
}