import React from 'react';
import SimpleBar from 'simplebar-react';
import { Container, Col, ListGroup, Table, Form } from 'react-bootstrap';

// Components
import { ErrorMessage } from '../../Extras';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Styles
import './Other.scss';

export default class Other extends React.Component {
  constructor(props) {
    super(props);

    this.constants = [
      {
        name: 'ChatConstants',
        description: 'sAPBDB',
        value: 'fValue'
      },
      {
        name: 'DesignerConstants',
        description: 'sAPBDB',
        value: 'fValue'
      },
      {
        name: 'DesignerConstants2',
        description: 'sComment',
        value: 'fValue'
      },
      {
        name: 'MailConstants',
        description: 'sAPBDB',
        value: 'nValue'
      },
      {
        name: 'MarketplaceConstants',
        description: 'sAPBDB',
        value: 'fValue'
      },
      {
        name: 'MinigameConstants',
        description: 'sAPBDB',
        value: 'fValue'
      },
      {
        name: 'SkillratingConstants',
        description: 'sDescription',
        value: 'fValue'
      }
    ]

    this.state = { selected: "ChatConstants" }
  }

  componentDidMount() {
    if (this.props.history.location.hash) {
      this.setState({ selected: this.props.history.location.hash.substr(1) });
    }
  }

  handleSidebarSelect = key => {
    this.setState({ selected: key });
    this.props.history.replace({
        hash: `${key}`
    })
  }

  render() {
    return (
      <Container fluid className="other-component">
        <MetaDecorator title={this.state.selected + ' | Other'} />
        <Sidebar
          constants={this.constants}
          activeKey={this.state.selected} 
          handleSelect={this.handleSidebarSelect} 
        />
        <Content 
          selected={this.state.selected} 
          constants={this.constants}
        />
      </Container>
    )
  }
}

const Sidebar = props => (
  <>
    <Form.Group className="sidebar-mobile">
      <Form.Control 
        as='select' 
        id={"filter-selector-other-sidebar"}
        onChange={(e) => props.handleSelect(e.target.value)}
        value={props.activeKey ? props.activeKey : 'ChatConstants'} 
      >
        {props.constants.map((constant, i) => (
          <option key={i}>{constant.name}</option>
        ))}
      </Form.Control>
    </Form.Group>
    <Col md={2} className="sidebar">
      <SimpleBar style={{ height: 'calc(100vh - 56px)' }} className="content">
        <ListGroup variant="flush" activeKey={props.activeKey} onSelect={props.handleSelect}>
          {props.constants.map(constant => (
            <ListGroup.Item key={constant.name} action eventKey={constant.name}>
              {constant.name}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </SimpleBar>
    </Col>
  </>
)


const Content = props => (
  <Col md={10} className="content ml-sm-auto">
    <SimpleBar style={{ height: 'calc(100vh - 56px)' }}>
      {props.constants.map(constant => {
        if (props.selected === constant.name) {
          return (
            <Constant key={constant.name} constant={constant} />
          )
        }
        return null;
      })}
    </SimpleBar>
  </Col>
)

class Constant extends React.Component {
  constructor(props) {
    super(props);

    this.state = { constants: [] }

    this.request = utils.request.bind(this);
  }

  componentDidMount() {
    this.request(`/constants?constant=${this.props.constant.name}`, 'constants');
  }

  render() {
    if (this.state.constantsError) {
      return <ErrorMessage error={this.state.constantsError} />
    }

    if (this.state.constantsLoading) {
      return utils.loadingCircle();
    }

    return (
      <Table className="constants-table">
        <thead>
          <tr>
            <td>Description</td>
            <td>Value</td>
          </tr>
        </thead>
        <tbody>
          {this.state.constants.map(constant => (
            <tr key={constant.sAPBDB}>
              <td>{constant[this.props.constant.description]}</td>
              <td>{constant[this.props.constant.value]}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }
}