import React from 'react';
import SimpleBar from 'simplebar-react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

// Components
import { Header, DetailAccordion } from '../../DetailTemplate';
import { ErrorMessage, SmallTable } from '../../Extras';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Services
import { settingsService } from '../../../_services/settings';

// Styles
import '../../Detail/Detail.scss';

export default class Detail extends React.Component {
  constructor(props) {
    super(props);

    this.state = { }

    this.request = utils.request.bind(this);
  }

  componentDidMount() {
    this.getMission();
  }

  componentDidUpdate(prevProps) {
    if (this.props.sapbdb !== prevProps.sapbdb) {
      this.getMission();
    }
  }

  getMission() {
    this.request(`/missions/${this.props.sapbdb}`, 'mission');
  }

  render() {
    if (this.state.missionError) return <ErrorMessage error={this.state.missionError} />;

    if (this.state.missionLoaded) {
      return (
        <SimpleBar 
          style={{ height: 'calc(100vh - 56px)' }} 
          className="detail-component">
          <MetaDecorator title={this.state.mission.sMissionTitle} />
          <Header toggleCompactMode={this.props.toggleCompactMode}>
            <h4 
              className="d-inline align-middle" 
              dangerouslySetInnerHTML={{__html: utils.convertColorCode(this.state.mission.sMissionTitle)}}>
            </h4>
            {settingsService.get('site', 'debug') && 
              <span className="ml-3 debug">{this.state.mission.sAPBDB}</span>
            }
          </Header>
          <Description mission={this.state.mission} />
          <StageList mission={this.state.mission} />
        </SimpleBar>
      );
    } else {
      return utils.loadingCircle();
    }
  }
}

class Description extends React.Component {
  constructor(props) {
    super(props);

    this.information = {
      'Initialized By': utils.displayFactionAlt(this.props.mission.eFaction),
      'Min Group Size': this.props.mission.nGroupSizeMin,
      'Max Group Size': this.props.mission.nGroupSizeMax,
      'Disabled': utils.displayTrueFalse(this.props.mission.bDisabled),
      'Test': utils.displayTrueFalse(this.props.mission.bTest)
    }

    // Base Rewards
    let rewards = this.props.mission.eRewardPackage;
    this.baseRewards = {
      'Money': `$${rewards.nBaseCash_0} (Premium: $${rewards.nBaseCash_1})`,
      'Standing': `${rewards.nBaseContactStanding_0} (Premium: ${rewards.nBaseContactStanding_1})`,
      'Money (Lose)': `$${rewards.nBaseCash_0*rewards.fMissionRewardLoseMultiplier} (Premium: $${rewards.nBaseCash_1*rewards.fMissionRewardLoseMultiplier})`,
      'Standing (Lose)': `${rewards.nBaseContactStanding_0*rewards.fMissionRewardLoseMultiplier} (Premium: ${rewards.nBaseContactStanding_1*rewards.fMissionRewardLoseMultiplier})`,
    }
  }

  render() {
    return (
      <Container fluid as={Row} className="description">
        <Col md={12} lg={6} xl={3}>
          <SmallTable title="INFORMATION" rows={this.information} />
        </Col>
        <Col md={12} lg={6} xl={3}>
          <SmallTable title="REWARDS" rows={this.baseRewards} />
        </Col>
        {this.props.mission.aContacts.length > 0 &&
        <Col md={12} lg={6} xl={6}>
          <RelatedContacts contacts={this.props.mission.aContacts} />
        </Col>
        }
      </Container>
    );
  }
}

class StageList extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      open: settingsService.get('missions', 'stage_collapsed') || '1'
    }
  }
  
  handleClick = (val) => {
    this.setState({ open: val });
    settingsService.set('missions', 'stage_collapsed', val);
  }

  render() {
    const stages = [];

    this.props.mission.aStages.forEach(s => {
      if (!s.bIsConcurrent) {
        stages.push(s)
      }
    })

    return (
      <Container fluid>
        <div className="text-right collapse-buttons" style={{width: '100%'}}>
          <button onClick={() => this.handleClick('1')}>
            <i className="fas fa-angle-double-down"></i>
          </button>
          <button onClick={() => this.handleClick('0')}>
            <i className="fas fa-angle-double-up"></i>
          </button>
        </div>

        {stages.map((s, i) => (
          <Stage key={i} mission={this.props.mission} stage={s} index={i} open={this.state.open} />
        ))}
      </Container>
    );
  }
}

class Stage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { concurrentStage: false, open: props.open }
  }

  componentDidMount() {
    try {
      // find real index of filtered stage 
      let index = this.props.mission.aStages.findIndex(
        (stage) => { return stage === this.props.stage; }
      )
      // Check if next real stage is concurrent and set state accordingly
      if (this.props.mission.aStages[index+1].bIsConcurrent) {
        this.setState({ concurrentStage: this.props.mission.aStages[index+1] });
      }
    } catch {  }
  }

  hasConcurrentObjective(stage) {
    if (this.state.concurrentStage) {
      if (stage.eOperation.sUIDescription === this.state.concurrentStage.eOperation.sUIDescription) { 
        return false;
      }
      return true;
    }
    return false;
  }

  targets(stage) {
    let text = '';
    
    // Check if stage requires targets to be completed
    if (stage.nTargetsRequired > 0) {
      let targets = stage.nTargetsRequired;
      let secondaryTargets = 0;

      // Concurrent checks - objective type
      if (this.state.concurrentStage) {
        let concurrent = this.state.concurrentStage;
        if (stage.eOperation.sUIDescription === concurrent.eOperation.sUIDescription) {
          targets += concurrent.nTargetsRequired;
        } else {
          secondaryTargets = concurrent.nTargetsRequired;
        }
      }

      // display text for required targets
      text = `// ${targets} ${stage.eOperation.sUIDescription.toLowerCase()}${targets > 1 ? 's' : ''} `;
      if (secondaryTargets) {
        text += `and ${secondaryTargets} ${this.state.concurrentStage.eOperation.sUIDescription.toLowerCase()}${secondaryTargets > 1 ? 's' : ''} `;
      }
      
      // Check if items on this stage
      let items = 0;
      if (stage.nTaskItemsRequired) {
        items = stage.nTaskItemsRequired;
        if (this.state.concurrentStage) items += this.state.concurrentStage.nTaskItemsRequired;
      } else if (this.state.concurrentStage.nTaskItemsRequired) {
        items = this.state.concurrentStage.nTaskItemsRequired;
      } else if (stage.nTaskItemsAvailable) {
        items = stage.nTaskItemsAvailable;
        if (this.state.concurrentStage) items += this.state.concurrentStage.nTaskItemsAvailable;
      }

      // Show Items
      if (items) {
        text += `// ${items} item${items > 1 ? 's' : ''} `;

        // Get item visuals
        if (stage.eTaskItemVariety) {
          text += `(${stage.eTaskItemVariety.eTaskItemVisual.sAPBDB.toLowerCase()}) `;
        }
      }

      // Vehicle to dropoff
      let vehicles = stage.nVehiclesRequired;
      if (this.state.concurrentStage) vehicles += this.state.concurrentStage.nVehiclesRequired;
      if (vehicles) {
        text += `// ${vehicles} vehicle${vehicles > 1 ? 's' : ''} `;
      }
    }
    return text;
  }

  timeLimit(stage) {
    // Use time from concurrent stage if 
    if (this.state.concurrentStage) {
      stage = this.state.concurrentStage;
    }

    if (stage.bBonusTime) {
      return `+${stage.nTimeLimit}`;
    }
    return stage.nTimeLimit;
  }

  finalTargets(stage) {
    let text = '';

    // Hold Point Targets
    if (stage.eOperation.bUseObjectiveHoldPoints) {
      text += `// ${stage.eOperation.nObjectiveHoldPointsTarget} points `;
    // Takeouts and VIP
    } else if (!stage.bDisableTakeouts) {
      // Decide which side is VIP and display lives
      if (stage.bEnableOwningSideVIPTakeouts) {
        text += `// ${this.props.mission.nOwningSideVIPLives} vip lives (owner side) `;
      }
      if (stage.bEnableOpposingSideVIPTakeouts) {
        text += `// ${this.props.mission.nOpposingSideVIPLives} vip lives (opposing side) `;
      }
      // Check if takout count is not 0
      if (this.props.mission.nTakeoutCount) {
        text += `// ${this.props.mission.nTakeoutCount} takeouts`;
      }
    }
    return text;
  }

  continueOnLoss(stage) {
    if (stage.bDrawOnTimeOut || this.state.concurrentStage.bDrawOnTimeOut) {
      return '// stage continues on draw';
    }
    return '';
  }

  winOnUnopposedMission(stage) {
    if (stage.bWinOnUnopposedCompletion || this.state.concurrentStage.bWinOnUnopposedCompletion) {
      return '// winning ends unopposed mission';
    }
    return '';
  }

  render() {
    let stage = this.props.stage;
    let index = this.props.index;

    var toggle = (
      <>
      {
        `STAGE ${index+1} // 
        ${utils.seperateCapitalLetters(stage.eOperation.eTaskOperationCategory.sAPBDB).toUpperCase()} `
      }
      {this.hasConcurrentObjective(stage) &&
      <>
        <span style={{color: 'yellow'}}>AND </span>
        {utils.seperateCapitalLetters(this.state.concurrentStage.eOperation.eTaskOperationCategory.sAPBDB).toUpperCase()}
      </>
      }
      <br />
      <span className="text-muted">
        {
          `${this.timeLimit(stage)} seconds
          ${this.targets(stage)}
          ${this.finalTargets(stage)}
          ${this.continueOnLoss(stage)}
          ${this.winOnUnopposedMission(stage)}`
        }
      </span>
      </>
    )

    var collapse = (
      <Row>
        <Col md={6} sm={12} className="pb-3">
          <h6>OWNER BRIEFING</h6>
          <p dangerouslySetInnerHTML={{__html:utils.convertColorCode(stage.sOwnerBrief)}} />  
        </Col>
        <Col md={6} sm={12}>
          <h6>DISPATCH BRIEFING</h6>
          <p dangerouslySetInnerHTML={{__html:utils.convertColorCode(stage.sDispatchBrief)}} />  
        </Col>
      </Row>
    )

    return (
      <DetailAccordion 
        ukey={stage.sAPBDB} 
        toggle={toggle} 
        collapse={collapse}
        open={this.props.open} />
    )
  }
}

const RelatedContacts = props => (
  <>
    <h6>CONTACTS</h6>
    <ul className="ul-columns-2">
      {props.contacts.map(contact => (
        <div key={contact.sAPBDB}>
          {contact.bDisabled !== 1 &&
          <li className="small">
            <NavLink to={`/contacts/${contact.eContact.sAPBDB}`}>
              {contact.eContact.sTitle}
            </NavLink>
          </li>}
        </div>
      ))}
    </ul>
  </>
)
